import _defined from "defined";
var exports = {};
var defined = _defined;
var units = ["mm", "cm", "m", "pc", "pt", "in", "ft", "px"];
var conversions = {
  // metric
  m: {
    system: "metric",
    factor: 1
  },
  cm: {
    system: "metric",
    factor: 1 / 100
  },
  mm: {
    system: "metric",
    factor: 1 / 1000
  },
  // imperial
  pt: {
    system: "imperial",
    factor: 1 / 72
  },
  pc: {
    system: "imperial",
    factor: 1 / 6
  },
  in: {
    system: "imperial",
    factor: 1
  },
  ft: {
    system: "imperial",
    factor: 12
  }
};
const anchors = {
  metric: {
    unit: "m",
    ratio: 1 / 0.0254
  },
  imperial: {
    unit: "in",
    ratio: 0.0254
  }
};

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

function convertDistance(value, fromUnit, toUnit, opts) {
  if (typeof value !== "number" || !isFinite(value)) throw new Error("Value must be a finite number");
  if (!fromUnit || !toUnit) throw new Error("Must specify from and to units");
  opts = opts || {};
  var pixelsPerInch = defined(opts.pixelsPerInch, 96);
  var precision = opts.precision;
  var roundPixel = opts.roundPixel !== false;
  fromUnit = fromUnit.toLowerCase();
  toUnit = toUnit.toLowerCase();
  if (units.indexOf(fromUnit) === -1) throw new Error("Invalid from unit \"" + fromUnit + "\", must be one of: " + units.join(", "));
  if (units.indexOf(toUnit) === -1) throw new Error("Invalid from unit \"" + toUnit + "\", must be one of: " + units.join(", "));

  if (fromUnit === toUnit) {
    // We don't need to convert from A to B since they are the same already
    return value;
  }

  var toFactor = 1;
  var fromFactor = 1;
  var isToPixel = false;

  if (fromUnit === "px") {
    fromFactor = 1 / pixelsPerInch;
    fromUnit = "in";
  }

  if (toUnit === "px") {
    isToPixel = true;
    toFactor = pixelsPerInch;
    toUnit = "in";
  }

  var fromUnitData = conversions[fromUnit];
  var toUnitData = conversions[toUnit]; // source to anchor inside source's system

  var anchor = value * fromUnitData.factor * fromFactor; // if systems differ, convert one to another

  if (fromUnitData.system !== toUnitData.system) {
    // regular 'm' to 'in' and so forth
    anchor *= anchors[fromUnitData.system].ratio;
  }

  var result = anchor / toUnitData.factor * toFactor;

  if (isToPixel && roundPixel) {
    result = Math.round(result);
  } else if (typeof precision === "number" && isFinite(precision)) {
    result = round(result, precision);
  }

  return result;
}

exports = convertDistance;
exports.units = units;
export default exports;
const _units = exports.units;
export { _units as units };